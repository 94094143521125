function LightboxFooter(props) {
    const {images, currentIndex} = props;
    return(
        <div className="lightbox-title">
            <p>{images[currentIndex].alt}</p>
        </div>
    )

}

export default LightboxFooter