import React, {useState} from "react";
import ReactPlayer from "react-player/lazy";
import ImageOverlay from "./lightbox-components/overlay";
import Loader from "./loader";

function VideoPlayer(props) {
  const { isOpen, currentIndex, videos, handleClose } = props;
  const [loading, setLoading] = useState(true)
  return (
    <React.Fragment>
      <ImageOverlay isOpen={isOpen} />

      <div className="player-close-btn" onClick={() => handleClose(false)}>
        <span className="iconify" data-icon="ion-ios-close-empty"></span>
      </div>

      {loading && <div
        className=""
        style={{
          position: "fixed",
          zIndex: 106,
          width: "100%",
          height: "100vh",
          display: 'flex',
          justifyContent: "center",
          alignItems:"center",
          top: 0,
          left: 0,
        }}
      >
        <Loader />
      </div>}

      <div className="player-wrapper">
        <ReactPlayer
          url={videos[currentIndex].link}
          playing={true}
          className="react-player"
          height="540px"
          onReady={()=> setLoading(false)}
        />
      </div>
    </React.Fragment>
  );
}

export default VideoPlayer;
