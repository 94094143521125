import React, { useState, useEffect } from "react";
import Masonry from "react-masonry-css";
import fireDB from "../services/img-data";

import CustomLightbox from "./lightbox";

function Gallery({selected, imageData, setImageData, setLoading}) {

  useEffect(() => {
    if(imageData.length === 0){
      fireDB.firestore().collection("myworks").get()
      .then((querySnapshot) => {
        let tmp = []
        querySnapshot.forEach((doc) => {
            tmp.push(doc.data())
        });
        setImageData(tmp)
      })
    }
  },[])
 

  const [isOpen, setOpen] = useState(false);
  const [currentImageIndex, setCurrentIndex] = useState(0);

  const filteredImages = imageData.filter((obj) => {
    if(selected === 'all' || selected === obj.type || selected === obj.artType) return true;
    return false
  })

  const images = filteredImages.map(({ type, title, fileUrl }) => ({
    src: fileUrl,
    alt: title,
  }));

  const breakpointColumnsObj = {
    default: 4,
    1100: 3,
    // 700: 2,
    500: 2,
  };

  const handleImageClick = (i) => {
    setCurrentIndex(i)
    setOpen(true)
  } 

  return (
    <React.Fragment>

      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {filteredImages.map((img, index) => (
          <div key={index} onClick={() => handleImageClick(index)}>
            <img src={img.fileUrl} alt={img.title} onLoad={index === 0? ()=> setLoading(false): ()=>{}} />
          </div>
        ))}
      </Masonry>

      <CustomLightbox
        currentImageIndex={currentImageIndex}
        setCurrentIndex={setCurrentIndex}
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        images={images}
      />
    </React.Fragment>
  );
}

export default Gallery;
