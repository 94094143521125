function ImageOverlay(props) {
  return (
    <div
      style={{
        display: props.isOpen ? 'block' : 'none',
        pointerEvents: 'none',
        width: "100%",
        height: "100vh",
        backgroundColor: "rgba(0,0,0,0.8)",
        position: "fixed",
        top:0,
        left:0,
        zIndex: 105
      }}
    ></div>
  );
}

export default ImageOverlay;
