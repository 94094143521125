function Sidebar({ darkMode, toggleDarkMode }) {
  return (
    <header>
      <div className="header-content">
        <h1>Ramsheed Kc</h1>
        <p>Photography, Digital Artist and Web Developer</p>
        <div
          className="darkicon"
          onClick={toggleDarkMode}
          style={{ backgroundColor: darkMode ? "white" : "transparent" }}
        >
          <span
            className="iconify"
            data-icon="bi:moon"
            data-inline="false"
          ></span>
        </div>
      </div>
    </header>
  );
}

export default Sidebar;
