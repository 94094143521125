import React, { useState } from "react";

function FilterMenu({ setFilter, setLoading }) {
  const [filterMenuStatus, setFilterMenuStatus] = useState(false);

  const toggleFilterMenu = () => {
    setFilterMenuStatus(filterMenuStatus ? false : true);
  };

  const handleFilterSelect = (selected) => {
    setFilter(selected)
    setLoading(selected === 'video' ? true: false)
    toggleFilterMenu();
  };

  return (
    <React.Fragment>
      <div
        className="filter-btn"
        onClick={toggleFilterMenu}
        style={{ color: filterMenuStatus ? "aqua" : "inherit" }}
      >
        <span
          className="iconify"
          data-icon="bi:filter-circle-fill"
          data-inline="false"
        ></span>
      </div>

      {filterMenuStatus && (
        <div className="filter-menu-wrapper">
          <div className="filter-menu">
            <ul>
              <li onClick={() => handleFilterSelect("all")}>All</li>
              <li onClick={() => handleFilterSelect("art")}>Art</li>
              <li onClick={() => handleFilterSelect("photography")}>Photography</li>
              <li onClick={() => handleFilterSelect("bw")}>BW</li>
              <li onClick={() => handleFilterSelect("digital")}>Digital</li>
              <li onClick={() => handleFilterSelect("video")}>Videos</li>
            </ul>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default FilterMenu;
