function ButtonsOverlay(props) {
  const { gotoNext, gotoPrevious, canNext, canPrev, isOpen, onClose } = props;
  return (
    <div className="lightbox-btn-wrapper"
      style={{
        display: isOpen ? "flex" : "none"
      }}
    >
      <div className="close-btn" onClick={onClose} >
        <span className="iconify" data-icon="ion-ios-close-empty"></span>
      </div>

      <div className="next-btn" onClick={gotoNext} style={{visibility: canNext ? "visible" : "hidden"}}>
        <span className="iconify" data-icon="ion-ios-arrow-right"></span>
      </div>

      <div className="prev-btn" onClick={gotoPrevious} style={{visibility: canPrev ? "visible" : "hidden"}}>
        <span className="iconify" data-icon="ion-ios-arrow-left"></span>
      </div>
    </div>
  );
}

export default ButtonsOverlay;
