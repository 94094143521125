import React from "react";
import Lightbox from "react-spring-lightbox";
import ImageOverlay from "./lightbox-components/overlay";
import ButtonsOverlay from "./lightbox-components/buttons";
import LightboxFooter from "./lightbox-components/footer";

const CustomLightbox = (props) => {
  const { currentImageIndex, setCurrentIndex, images, isOpen, onClose } = props;

  const gotoPrevious = () =>
    currentImageIndex > 0 && setCurrentIndex(currentImageIndex - 1);

  const gotoNext = () =>
    currentImageIndex + 1 < images.length &&
    setCurrentIndex(currentImageIndex + 1);

  return (
    <React.Fragment>
      <ImageOverlay isOpen={isOpen} />
      <ButtonsOverlay
        isOpen={isOpen}
        gotoNext={gotoNext}
        gotoPrevious={gotoPrevious}
        onClose={onClose}
        canNext={currentImageIndex + 1 < images.length }
        canPrev={currentImageIndex > 0}
      />

      <div style={{ position: "relative", zIndex: 101 }}>
        <Lightbox
          isOpen={isOpen}
          onPrev={gotoPrevious}
          onNext={gotoNext}
          images={images}
          currentIndex={currentImageIndex}
          onClose={onClose}
          style={{ padding: "15px" }}
          renderFooter={() => (<LightboxFooter images={images} currentIndex={currentImageIndex}/>)}


          /* Add your own UI */
          // renderHeader={() => (<CustomHeader />)}
          // renderPrevButton={() => (<CustomLeftArrowButton />)}
          // renderNextButton={() => (<CustomRightArrowButton />)}

          /* Add styling */
          // className="cool-class"

          /* Handle closing */
          // onClose={handleClose}

          /* Use single or double click to zoom */
          // singleClickToZoom

          /* react-spring config for open/close animation */
          pageTransitionConfig={{
            from: { transform: "scale(0.75)", opacity: 0 },
            enter: { transform: "scale(1)", opacity: 1 },
            leave: { transform: "scale(0.75)", opacity: 0 },
            config: { mass: 1, tension: 120, friction: 20 },
          }}
        />
      </div>
    </React.Fragment>
  );
};

export default CustomLightbox;
