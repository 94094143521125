import React, { useState } from "react";
import Sidebar from "./sidebar";
import Gallery from "./gallery";
import FilterMenu from "./filter-menu";
import VideoGallery from "./video-gallery";
import Loader from "./loader";

function Home(props) {
  const [filter, setFilter] = useState("all");
  const [imageData, setImageData] = useState([]);
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);

  return (
    <div className="main">
      <Sidebar
        darkMode={props.darkMode}
        toggleDarkMode={props.toggleDarkMode}
      />

      <div>
        {loading && <div
          style={{
            position: "fixed",
            zIndex: 106,
            width: "100%",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            top: 0,
            left: 0,
          }}
        >
          <Loader />
        </div>}

        {filter !== "all" && <div className="category-head">{filter}</div>}
        {filter === "video" ? (
          <VideoGallery
            videos={videos}
            setVideos={setVideos}
            setLoading={setLoading}
          />
        ) : (
          <Gallery
            imageData={imageData}
            setImageData={setImageData}
            selected={filter}
            setLoading={setLoading}
          />
        )}
      </div>

      <FilterMenu setFilter={setFilter} setLoading={setLoading} />
    </div>
  );
}

export default Home;
