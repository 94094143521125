import React, { useEffect, useState } from "react";
import { getAllVideosUrl } from "../services/vimeoUrls";
import { Vimeo } from "vimeo";
import VideoPlayer from "./video-player";

function VideoGallery({ videos, setVideos, setLoading }) {
  const [isOpen, setIsOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (videos.length === 0) {
      const vimeoVids = new Vimeo(
        "5cb6f167902073789b6c9955ac1e5ad3ac77315a",
        "Oy5bTTScrBsw6Q1dhhgYgfR7e53Y1VJa/CjxNkk99wvUPvO8bvbY9xHOr9LPskgPPcRIsUWGqkhS1z5AYfOlhBsvhzvfIWR7kYkB7B7g8uylYFTy0RffSdh1SbuVTtit",
        "0d1087a143dc745773fe326a705b8ebb"
      );

      vimeoVids.request({ path: getAllVideosUrl }, (e, data) => {
        let tmp = data.data.map((obj) => {
          return {
            link: obj.link,
            poster: obj.pictures.sizes[3].link,
            duration: obj.duration,
          };
        });
        setVideos(tmp);
      });
    }
  }, []);

  const handlePlayClick = (index) => {
    setCurrentIndex(index);
    setIsOpen(true);
  };

  return (
    <React.Fragment>
      <div className="video-gallery">
        {videos.map((video, index) => (
          <div className="video-card" key={index}>
            <img src={video.poster} alt="" onLoad={index === 1? ()=> setLoading(false): ()=>{}} />
            <div className="video-card-info">
              <div className="play-icon" onClick={() => handlePlayClick(index)}>
                <span
                  className="iconify"
                  data-icon="bi:play-circle"
                  data-inline="false"
                ></span>
              </div>
              <div className="duration">{video.duration} sec</div>
            </div>
          </div>
        ))}
      </div>

      {isOpen && (
        <VideoPlayer
          currentIndex={currentIndex}
          isOpen={isOpen}
          videos={videos}
          handleClose={setIsOpen}
        />
      )}
    </React.Fragment>
  );
}

export default VideoGallery;
