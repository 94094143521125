import React, { useState, useEffect } from "react";
import Home from "./components/home";
import "./App.css";

function App() {
  const storedDarkMode = JSON.parse(localStorage.getItem("DARK_MODE"));
  const [darkMode, setDarkMode] = useState(storedDarkMode);

  useEffect(() => {
    localStorage.setItem("DARK_MODE", darkMode);
    changeCssVars(darkMode);
  }, [darkMode]);

  const toggleDarkMode = () => {
    setDarkMode(darkMode ? false : true);
  };

  const changeCssVars = (darkMode) => {
    document.documentElement.style.setProperty(
      "--bg-color",
      darkMode ? "#000" : "#fff"
    );

    document.documentElement.style.setProperty(
      "--text-color",
      darkMode ? "rgb(235, 235, 235)" : "#000"
    );

    document.documentElement.style.setProperty(
      "--card-color",
      darkMode ? "#212121" : "#eeeeee"
    );
  };

  return (
    <div className="App">
      <Home darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
    </div>
  );
}

export default App;

